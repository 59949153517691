<template>
    <div class="wrap">
        <el-tabs v-model="activeName">
            <el-tab-pane label="医疗单据识别" name="0"></el-tab-pane>
        </el-tabs>
        <!-- 标签内容  -->
        <div class="tab_content">
            <el-form :inline="true" :model="navForm">
                <el-form-item label="案件号：">
                    <el-input class="width_220" v-model="navForm.caseNo" type="text" placeholder="请输入" clearable></el-input>
                    <div class="demo_num">
                        <i class="el-icon-info" style="color: #409eff; font-size: 13px; margin-right: 5px"></i>
                        <span>示例案件号：812020080703202409051000194</span>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="margin-left: 10px" @click="queryCase">查 询</el-button>
                </el-form-item>
            </el-form>
            <div class="img_box" v-if="imgType.length > 0">
                <div class="img_type" v-for="(item, index) in imgType" :key="index">
                    <p>{{ item }}</p>
                    <div :class="{ img_content: 1, clickStyle: imgItem.ocrType == '医疗发票' || imgItem.ocrType == '医疗费用清单' }" v-for="(imgItem, imgIndex) in classifiedData[item]" :key="imgIndex" @click="showImgInfo(imgItem)">
                        <span v-if="imgItem.ocrType == '医疗发票' || imgItem.ocrType == '医疗费用清单'">{{ imgItem.ocrType }}</span>
                        <img :src="imgItem.fileUrl" />
                    </div>
                </div>
            </div>
            <el-empty class="noneType" description="暂无数据" v-else></el-empty>
        </div>
        <!-- 转派 弹窗 -->
        <el-dialog :visible.sync="infoFlag" :modal-append-to-body="false" width="900px" center>
            <div class="imgInfoContent clearfix" v-loading="previewLoading">
                <div class="info_left fl">
                    <img-deal ref="imgDeal" :url="previewData.fileUrl"></img-deal>
                </div>
                <div class="info_right fr" v-if="previewData.result">
                    <template v-if="previewData.ocrType == '医疗费用清单'">
                        <el-row class="header_row">
                            <el-col :span="4">项目类别</el-col>
                            <el-col :span="5">项目名称</el-col>
                            <el-col :span="4" :offset="1">单价</el-col>
                            <el-col :span="3">数量</el-col>
                            <el-col :span="3">单位</el-col>
                            <el-col :span="4">金额</el-col>
                        </el-row>
                        <div class="list_box">
                            <div class="list_content">
                                <el-row v-for="(listItem, listIndex) in previewData.result['清单项']" :key="listIndex">
                                    <el-col :class="{ placeholder: !listItem['项目类别'] }" :span="4">{{ listItem['项目类别'] || '--' }}</el-col>
                                    <el-col :class="{ placeholder: !listItem['项目名称'] }" :span="5">{{ listItem['项目名称'] || '--' }}</el-col>
                                    <el-col :class="{ placeholder: !listItem['单价'] }" :span="4" :offset="1">{{ listItem['单价'] || '--' }}</el-col>
                                    <el-col :class="{ placeholder: !listItem['数量'] }" :span="3">{{ listItem['数量'] || '--' }}</el-col>
                                    <el-col :class="{ placeholder: !listItem['单位'] }" :span="3">{{ listItem['单位'] || '--' }}</el-col>
                                    <el-col :class="{ placeholder: !listItem['金额'] }" :span="4">{{ listItem['金额'] || '--' }}</el-col>
                                </el-row>
                            </div>
                        </div>
                        <el-row class="total_row" v-if="previewData.result['清单项'] && previewData.result['清单项'].length > 0">
                            <el-col :span="2">合计</el-col>
                            <el-col :span="6" :offset="2">项目数：{{ previewData.result['项目数'] }}</el-col>
                            <el-col :span="10" :offset="1">总金额：{{ previewData.result['合计金额'] }}</el-col>
                        </el-row>
                    </template>
                    <template v-if="previewData.ocrType == '医疗发票'">
                        <div class="invoice_details">
                            <el-row>
                                <!-- <el-col :span="12">清单名：{{ previewData.result['清单名'] }}</el-col> -->
                                <el-col :span="24">发票编号：{{ previewData.result['发票编号'] }}</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="12">交款人：{{ previewData.result['交款人'] }}</el-col>
                                <el-col :span="12">收款人：{{ previewData.result['收款人'] }}</el-col>
                            </el-row>
                        </div>
                        <el-row style="margin-bottom: 10px">
                            <el-col :span="7">项目名称</el-col>
                            <el-col class="placeholder" :span="6" :offset="1">数量/单位</el-col>
                            <el-col :span="5" :offset="1">金额</el-col>
                        </el-row>
                        <div class="list_content invoice_right">
                            <el-row v-for="(listItem, listIndex) in previewData.result['清单项']" :key="listIndex">
                                <el-col :class="{ placeholder: !listItem['名称'] }" :span="7">{{ listItem['名称'] || '--' }}</el-col>
                                <el-col :class="{ placeholder: !listItem['数量'] }" :span="6" :offset="1">{{ listItem['数量'] || '--' }}</el-col>
                                <el-col :class="{ placeholder: !listItem['金额'] }" :span="5" :offset="1">{{ listItem['金额'] || '--' }}</el-col>
                            </el-row>
                        </div>
                        <el-row class="total_row" v-if="previewData.result['清单项'] && previewData.result['清单项'].length > 0">
                            <el-col :span="4">合计</el-col>
                            <el-col :span="5" :offset="2">项目数：{{ previewData.result['项目数'] }}</el-col>
                            <el-col :span="10" :offset="1">总金额：{{ previewData.result['合计金额'] }}</el-col>
                        </el-row>
                    </template>
                </div>
                <p class="noneData" style="margin-top: 200px" v-else>暂无数据</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import ImgDeal from '@/components/ImgDeal.vue';
export default {
    components: {
        ImgDeal
    },
    data() {
        return {
            activeName: '0',
            infoFlag: false,
            previewLoading: false,
            navForm: {
                // caseNo: '812020080703202401281000054',
                // caseNo: '602020080703202408141000053',
                caseNo: '',
                internal: true
            },
            classifiedData: {},
            imgType: [],
            previewData: {}
        };
    },
    mounted() {
        this.queryCase();
        // this.showImgInfo({
        //     ocrType: '医疗费用清单',
        //     caseNo: '812020080703202401281000054',
        //     ossPath: 'caseFile/812020080703202401281000054/对方医疗费用清单/32274114.jpg',
        //     fileUrl:
        //         'http://sigreal-jiaanan-service.oss-cn-beijing.aliyuncs.com/caseFile/812020080703202401281000054/%E5%AF%B9%E6%96%B9%E5%8C%BB%E7%96%97%E8%B4%B9%E7%94%A8%E6%B8%85%E5%8D%95/32274114.jpg?Expires=1729654766&OSSAccessKeyId=LTAI5tRm4CD3its46qtEiwCC&Signature=g23V4uxApaRXovjDVGfhvS4sETY%3D'
        // });
        // this.showImgInfo({
        //     ocrType: '医疗发票',
        //     caseNo: '812020080703202401281000054',
        //     ossPath: 'caseFile/812020080703202401281000054/对方门诊发票/32274131.jpg',
        //     fileUrl: 'http://sigreal-jiaanan-service.oss-cn-beijing.aliyuncs.com/caseFile/812020080703202401281000054/%E5%AF%B9%E6%96%B9%E9%97%A8%E8%AF%8A%E5%8F%91%E7%A5%A8/32274131.jpg?Expires=1729655947&OSSAccessKeyId=LTAI5tRm4CD3its46qtEiwCC&Signature=GBZ3RhFodBe7RycJFERaMcfh4fw%3D'
        // });
    },
    methods: {
        queryCase() {
            this.instance.post('/demoImage/searchCaseImage', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.classifiedData = {};
                    this.imgType = [];
                    data.data.forEach((item) => {
                        if (!this.classifiedData[item.ocrType]) {
                            this.classifiedData[item.ocrType] = [];
                        }
                        this.classifiedData[item.ocrType].push(item);
                    });
                    for (const key in this.classifiedData) {
                        this.imgType.push(key);
                    }

                    // 类型排序,其他在最后
                    const otherIndex = this.imgType.indexOf('其他');
                    if (otherIndex !== -1) {
                        const otherItem = this.imgType.splice(otherIndex, 1)[0];
                        this.imgType.push(otherItem);
                    }
                }
            });
        },
        showImgInfo(imgItem) {
            if (imgItem.ocrType == '医疗发票' || imgItem.ocrType == '医疗费用清单') {
                this.infoFlag = true;
                this.previewData = {
                    result: '',
                    fileUrl: ''
                };
                this.previewLoading = true;
                this.instance.post('/demoImage/detailImage', { caseNo: imgItem.caseNo, ossPath: imgItem.ossPath }).then((res) => {
                    this.previewLoading = false;
                    let data = res.data;
                    if (data.code == 1 && data.data) {
                        this.previewData = {
                            result: JSON.parse(data.data.ocrResult),
                            fileUrl: imgItem.fileUrl,
                            ocrType: data.data.ocrType
                        };
                    }
                });
            }
        }
    }
};
</script>
<style>
@import '../../assets/css/table_public.css';
</style>
<style lang="less" scoped>
.tab_content {
    background: #fff;
    padding: 20px;
    min-height: 80vh;
    .img_box {
        height: 70vh;
        overflow-y: auto;
        p {
            margin-bottom: 5px;
            padding-bottom: 5px;
            margin-top: 20px;
            // border-bottom: 1px solid red;
        }
        .img_type {
            .img_content {
                display: inline-block;
                margin: 0 20px 10px 0;
                border: 1px solid #ccc;
                position: relative;
                span {
                    position: absolute;
                    right: 5px;
                    bottom: 5px;
                    z-index: 5;
                    color: #409eff;
                }
                img {
                    width: 200px;
                    height: 150px;
                    object-fit: contain;
                }
            }
            .clickStyle {
                cursor: pointer;
            }
        }
    }
    .noneType {
        margin-top: 150px;
    }
}
.imgInfoContent {
    .info_left {
        width: 40%;
        height: 500px;
        border: 1px solid #ccc;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    .info_right {
        width: 55%;
        height: 500px;
        .header_row {
            width: 458px;
        }
        .invoice_details {
            margin-bottom: 30px;
            .el-row {
                margin-bottom: 20px;
            }
        }
        .list_box {
            max-height: 450px;
            overflow-y: auto;
            .list_content {
                width: 458px;
                margin-bottom: 20px;
                .el-row {
                    margin-bottom: 10px;
                }
            }
            .total_row {
                margin-top: 20px;
            }
        }
        .invoice_right {
            max-height: 350px;
            overflow-y: auto;
            margin-bottom: 20px;
        }
        .placeholder {
            visibility: hidden;
        }
    }
}
</style>