<template>
    <div class="wrap">
        <!-- <div class="page_title"><b>OCR工具</b></div> -->
        <div class="main page_content">
            <el-tabs v-model="activeName" @tab-click="handleClickTab">
                <el-tab-pane label="图片相似度" name="second2"> </el-tab-pane>
            </el-tabs>
            <div class="toolbox_page">
                <template v-if="activeName == 'second2'">
                    <div class="pane_box">
                        <el-row :gutter="20">
                            <el-col :span="4">
                                <div class="grid-content bg-purple">
                                    <el-upload
                                        class="upload_component"
                                        :data="{ caseNo: 123456789, type: 9901 }"
                                        :action="baseURL + '/tpa/ocrAbility/pictureCheckRepeat'"
                                        :headers="unloaderHeader"
                                        :before-upload="beforeAvatarUpload"
                                        :on-success="handleRepeatCheck"
                                        :http-request="(e) => ossUpload(e, 'searchSimilarImages')"
                                        :show-file-list="false"
                                        list-type="picture-card"
                                    >
                                        <img v-if="uploadFile2.url" :src="uploadFile2.url" class="avatar" />
                                        <i class="el-icon-plus" v-else></i>
                                    </el-upload>
                                </div>
                            </el-col>
                            <el-col :span="12" v-if="sourceImgUrl">
                                <img class="source_img" :src="sourceImgUrl" />
                            </el-col>
                        </el-row>
                        <div class="flex_box">
                            <div class="show-upload-image" v-if="!resultList || resultList.length < 1">
                                <el-skeleton style="width: 420px">
                                    <template slot="template">
                                        <div v-if="echoImgUrl" class="upload_title" style="padding: 15px 0 10px 14px">无重复影像风险</div>
                                        <div v-else style="padding: 14px">
                                            <el-skeleton-item variant="p" style="width: 60%" />
                                            <el-skeleton-item variant="p" style="width: 60%" />
                                        </div>
                                        <el-skeleton-item variant="image" style="width: 360px; height: 240px; margin: 0px 0 0 15px" />
                                    </template>
                                </el-skeleton>
                            </div>
                            <ul class="result_ul clearfix autoC" v-else-if="resultList && resultList.length > 0">
                                <template v-if="resultList">
                                    <li class="fl" v-for="(item, index) in resultList" :key="index">
                                        <div class="li_tooltip">
                                            <p>
                                                案件号：
                                                <el-tooltip effect="dark" v-if="item.caseNo.length > 20" :content="item.caseNo" placement="top-start">
                                                    <span>{{ item.caseNo.slice(0, 20) + '...' }}</span>
                                                </el-tooltip>
                                                <span style="margin-left: 3px" v-else>{{ item.caseNo }}</span>
                                            </p>
                                            <p>
                                                相似度：<span style="margin-left: 3px">{{ item.distance ? item.distance : '' }}</span>
                                            </p>
                                        </div>
                                        <img v-if="item && item.previewPath" :src="item.previewPath" />
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import OSS from 'ali-oss';
import { v4 as uuidv4 } from 'uuid';
import { getToken } from '../../utils/auth.js';
export default {
    name: 'technologicalTool',
    data() {
        return {
            loading: false,
            fullscreenLoading: false,
            activeName: 'second2',
            beforeUploadData: {},
            baseURL: '',
            url: '',
            unloaderHeader: { token: '' }, //上传的请求头
            idCardFrontUrl: '',
            idCardBackUrl: '',
            type: '',
            desc: '',
            dataList: [],
            uploadFile1: {},
            uploadFile2: {},
            uploadFile2_1: {},
            uploadFile3: {},
            uploadFile4: {},
            uploadFile5: {},
            invoiceUrl: '',
            invoiceCheckMessage: '',
            invoiceDataList: [],

            repeatCaseNo: '',
            repeatImgUrl: [],

            echoImgUrl: '',
            psCaseNo: '',
            psImgUrl: '',
            uploadPsData: {},

            qrCodeDataList: [],

            uploadData: [
                {
                    id: 1,
                    title: '上传材料：',
                    // src:require('../../../../public/images/car-damage/success_icon.png'),
                    remark: '',
                    fileList: []
                }
            ],

            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            navForm: {
                organicCode: ''
            },
            isDisabled: false,
            maotaiResult: {
                certCode: '',
                certAudType: '',
                productCatalogName: '',
                productTypingName: '',
                productDimensionUnit: '',
                markUsePattern: '',
                certAuthorityName: '',
                certOrgName: '',
                certStatus: ''
            },
            pageTitle: '',
            sourceImgUrl: null,
            tmpOSSClient: null,
            resultList: []
        };
    },
    mounted() {
        this.pageTitle = this.$route.meta.title;
        this.baseURL = this.$store.getters.baseURL;

        this.initOSSClient();
    },
    methods: {
        // 初始化OSS
        initOSSClient() {
            this.instance.get('/aliyunoss/access', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.tmpOSSClient = new OSS({
                        accessKeyId: data.data.accessKeyId,
                        accessKeySecret: data.data.accessKeySecret,
                        stsToken: data.data.securityToken,
                        // region表示您申请OSS服务所在的地域，例如oss-cn-hangzhou。
                        region: data.data.region,
                        bucket: data.data.bucket
                    });
                    console.log(this.tmpOSSClient);

                    this.tmpOSSClient.resetOss = true;
                } else {
                    console.log('oss初始化失败，正在重试！');
                    // this.initOSSClient();
                }
            });
        },
        initResultPath(resultData) {
            resultData.forEach((res) => {
                res.previewPath = res.ossPath ? this.tmpOSSClient.signatureUrl(res.ossPath) : this.tmpOSSClient.signatureUrl(res.materialPath);
            });
        },
        formatDate(date) {
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的，所以要+1
            let day = date.getDate().toString().padStart(2, '0');
            return `${year}/${month}/${day}`;
        },
        //OSS直传
        ossUpload(e, uploadType) {
            this.resultList = null;
            let file = e.file;
            let _this = this;
            let storeAs = 'upload-file';
            let protocol = location.protocol;
            let tmpSuffix = file.name.split('.')[1];
            let fileTypeList = ['tiff', 'jfif', 'bmp', 'webp', 'gif', 'svg', 'jpg', 'svgz', 'png', 'jpeg', 'ico', 'xbm', 'dib', 'pjp', 'apng', 'tif', 'pjpeg', 'avif'];
            let tmpImageUrl;
            this.sourceImgUrl = null;
            const reader = new FileReader();
            reader.readAsDataURL(file); // 将文件转换成 Base64 格式
            reader.onloadend = () => {
                tmpImageUrl = reader.result; // 保存 Base64 URL 地址
            };

            if (!uploadType && fileTypeList.indexOf(tmpSuffix) == -1) {
                this.$message.error('请选择正确文件类型');
                return;
            }

            // storeAs可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
            // file可以自定义为File对象、Blob数据以及OSS Buffer。
            // storeAs = result.path + '/' + uuidv4() + '.' + file.name.split('.')[1];

            storeAs = `tmp-file/${this.formatDate(new Date())}/${uuidv4()}.${file.name.split('.')[1]}`;

            this.tmpOSSClient
                .multipartUpload(storeAs, file)
                .then(function (result) {
                    // storeAs - 阿里返回图片路径
                    switch (uploadType) {
                        case 'searchSimilarImages':
                            _this.searchSimilarImages({ ossPath: storeAs });
                            break;

                        default:
                            break;
                    }
                })
                .catch(function (err) {
                    console.log(err);
                });
        },
        searchSimilarImages(searchData) {
            console.log('-1');
            this.instance.post('/caseSearch/searchSimilarPicture', searchData).then((res) => {
                this.fullscreenLoading.close();
                let data = res.data || null;
                if (data.code == 1 && data.data) {
                    this.sourceImgUrl = this.tmpOSSClient.signatureUrl(searchData.ossPath);
                    this.resultList = data.data;
                    this.initResultPath(this.resultList);
                }
            });
        },
        //切换tab
        handleClickTab() {
            this.sourceImgUrl = null;
            this.resultList = [];
        },
        handleRepeatCheck(res, file) {
            this.uploadFile2 = file;
            this.fullscreenLoading.close();
            this.repeatImgUrl = [];
            this.echoImgUrl = file.url;
            this.repeatCaseNo = res.data.caseNo;
            this.repeatImgUrl = res.data.imageUrl ? res.data.imageUrl : [];
            if (res.code == 500) {
                if (res.message) {
                    this.$message.error(res.message);
                } else {
                    this.$message.error('抱歉，识别失败，请重新上传！');
                }
                return false;
            }
            if (this.uploadFile2 && res.data.imageUrl) {
                this.uploadFile2_1 = file;
            }
        },
        //上传文件之前的钩子
        beforeAvatarUpload(file) {
            if (file.size / 1024 > 5000) {
                this.$message.error('抱歉，上传文件不得大于5M！');
                return false;
            }
            this.url = file.url;
            this.unloaderHeader.Authorization = 'Bearer ' + getToken();
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '上传中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
        }
    }
};
</script>
<style lang="less" scoped>
.source_img {
    width: 148px;
    height: 148px;
    object-fit: contain;
    border: 1px solid #e5e5e5;
}
.result_ul {
    width: 100%;
    padding: 10px;
    li {
        width: 19%;
        height: 295px;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        margin-right: 10px;
        margin-top: 10px;
        .li_tooltip {
            border-bottom: 1px solid #e5e5e5;
            padding: 8px 10px;
            p {
                margin-bottom: 5px;
                span {
                    color: #409eff;
                }
            }
        }
        .li_tooltip:nth-child(1) {
            cursor: pointer;
        }
        img {
            width: 100%;
            height: 220px;
            margin-top: 10px;
            object-fit: contain;
        }
    }
    li:nth-child(5n) {
        margin-right: 0;
    }
}
.show-upload-image {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 15px;
    /* margin-right: 10px; */
    min-height: 320px;
    height: 320px;
    width: 460px;
    display: flex;
    margin-left: 10px;
}

.upload_title {
    font-size: 16px;
}

.upload_title_red {
    color: #e4393c;
}

.more_images {
    flex: 1;
}

.more_images .show-upload-image2 {
    width: auto;
}

.more_images .images_grounp {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.more_images .images_grounp > div {
    width: 49%;
}

.show-upload-image2 {
    width: 460px;
    margin-left: 30px;
}

.flex_box {
    display: flex;
    width: 100%;
    height: 600px;
}

.result_ul {
    height: 600px;
    overflow-y: auto;
}

.main {
    margin-top: 0.2rem;
    padding-top: 0.1rem;
    padding: 0.3rem;
}

.show-image {
    width: 100%;
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.show-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: auto;
    line-height: 178px;
    text-align: center;
}

.form-panel {
    border: 1px dashed #d9d9d9;
    cursor: pointer;
    width: 360px;
    /* height: auto; */
    margin: 15px 0 0 30px;
    border-radius: 6px;
    padding: 15px;
}

/deep/ .el-upload--picture-card {
    min-height: 148px;
    height: auto;
}

img {
    width: 100%;
}

.form-panel /deep/ .el-descriptions__body {
    height: 300px;
    overflow: scroll;
}

.page_content {
    min-height: 0.3rem;
    height: auto !important;
    background: #fff;
    padding-top: 0 !important;
}

.pane_box /deep/ .el-row {
    margin-left: 0 !important;
    margin-bottom: 10px;
}

.card-col_title {
    font-size: 14px;
    line-height: 15px;
}
</style>
