<template>
    <div class="case_card card_bottom-padding caseInfo">
        <el-row class="title_row">
            <el-col :span="12">
                <div class="card_title">
                    投诉案件详情
                    <el-tag type="warning" style="margin-left: 5px" v-if="caseDetailData && caseDetailData.suspendedFlag">挂起</el-tag>
                </div>
            </el-col>
        </el-row>
        <div class="card_content">
            <el-row>
                <el-col :span="24">
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">投诉编号：{{ caseDetailData && caseDetailData.complaintNumber ? caseDetailData.complaintNumber : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">保单号：{{ caseDetailData && caseDetailData.policyNo ? caseDetailData.policyNo : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">渠道：{{ caseDetailData && caseDetailData.complaintChannel ? caseDetailData.complaintChannel : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">投诉人：{{ caseDetailData && caseDetailData.complaintPerson ? caseDetailData.complaintPerson : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">投诉人身份：{{ caseDetailData && caseDetailData.complaintIdCard ? caseDetailData.complaintIdCard : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">投诉电话：{{ caseDetailData && caseDetailData.complaintPhone ? caseDetailData.complaintPhone : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="24">投诉内容：{{ caseDetailData && caseDetailData.complaintContent ? caseDetailData.complaintContent : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="24">诉求原因：{{ caseDetailData && caseDetailData.complaintCause ? caseDetailData.complaintCause : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">涉诉机构：{{ caseDetailData && caseDetailData.complaintOrganization ? caseDetailData.complaintOrganization : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">责任人：{{ caseDetailData && caseDetailData.liablePerson ? caseDetailData.liablePerson : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">核赔人：{{ caseDetailData && caseDetailData.checkIndemnityPerson ? caseDetailData.checkIndemnityPerson : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="7">涉诉省份：{{ caseDetailData && caseDetailData.province ? caseDetailData.province : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="2">是否升级：{{ caseDetailData && String(caseDetailData.upgradeFlag) == 'true' ? '是' : caseDetailData && String(caseDetailData.upgradeFlag) == 'false' ? '否' : '--' }}</el-col>
                        <el-col class="col_txt" :span="7" :offset="1">是否结案：{{ caseDetailData && String(caseDetailData.closeCaseFlag) == 'true' ? '是' : caseDetailData && String(caseDetailData.closeCaseFlag) == 'false' ? '否' : '--' }}</el-col>
                    </el-row>
                    <el-row class="content_row">
                        <el-col class="col_txt" :span="24">备注：{{ caseDetailData && caseDetailData.remark ? caseDetailData.remark : '--' }}</el-col>
                    </el-row>
                </el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        caseDetailData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false
        };
    },
    mounted() {},
    methods: {},
    watch: {
        caseDetailData: {
            handler(val) {
                if (val) {
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
    .card_content {
        .content_row {
            .title {
                font-weight: 600;
            }
            .col_title {
                // font-weight: 600;
                max-width: 80px;
            }
            .col_txt {
                line-height: 22px;
                word-break: break-all;
                // border: 1px solid red;
            }
            .winRate {
                display: inline-block;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                margin-right: 5px;
                position: relative;
                top: 1px;
            }
            .greenColor {
                background: #67c23a;
            }
            .redColor {
                background: red;
            }
        }
    }
}
</style>