<template>
    <div class="case_card card_bottom-padding caseInfo">
        <el-row class="title_row">
            <el-col :span="12">
                <div class="card_title">处理情况</div>
            </el-col>
        </el-row>
        <div class="card_content">
            <div class="situation" v-if="btnMenuData && btnMenuData[400]">
                投诉人来电投诉“{{ caseDetailData && caseDetailData.complaintCause ? caseDetailData.complaintCause : '' }}”事宜， 已协调理赔人员联系投诉人处理。沟通协商达“
                <el-form class="submit_form" ref="submitRef" :model="submitForm" :rules="submitRules">
                    <el-form-item label="" prop="unifyInfo">
                        <el-select class="width_60 styleInput" v-model="submitForm.unifyInfo" clearable placeholder="">
                            <el-option v-for="item in unifyInfoOptions" :key="item.dictName" :label="item.dictName" :value="item.dictName"> </el-option>
                        </el-select>
                    </el-form-item>
                    ”成一致意见。“
                    <el-form-item label="" prop="leavingMarks">
                        <el-select class="width_75 styleInput" v-model="submitForm.leavingMarks" clearable placeholder="">
                            <el-option v-for="item in leavingMarksOptions" :key="item.dictName" :label="item.dictName" :value="item.dictName"> </el-option>
                        </el-select>
                    </el-form-item>
                    ”留痕已上传。
                </el-form>
            </div>
            <div class="situation" v-else>
                投诉人来电投诉“{{ caseDetailData && caseDetailData.complaintCause ? caseDetailData.complaintCause : '' }}”事宜，已协调理赔人员联系投诉人处理。沟通协商达“{{ caseDetailData && caseDetailData.unifyInfo ? caseDetailData.unifyInfo : '' }}”成一致意见。“{{
                    caseDetailData && caseDetailData.leavingMarks ? caseDetailData.leavingMarks : ''
                }}”留痕已上传。
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        caseDetailData: {
            type: Object,
            default: null
        },
        btnMenuData: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            loading: false,
            unifyInfoOptions: [],
            leavingMarksOptions: [],
            submitForm: {
                flag: 2,
                unifyInfo: '',
                leavingMarks: ''
            },
            submitRules: {
                unifyInfo: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
                leavingMarks: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }]
            }
        };
    },
    mounted() {
        // 是否达成一致
        this.getPublicEnum('COMPLAINT_UNIFY_INFO', 'unifyInfoOptions');
        // 信息留痕
        this.getPublicEnum('COMPLAINT_LEAVING_MARKS_INFO', 'leavingMarksOptions');
    },
    methods: {
        validateForm() {
            this.$refs.submitRef.validate((valid) => {
                if (valid) {
                    this.$emit('submitFn', this.submitForm);
                } else {
                    this.$message.error('处理情况内容不完整，不能提交案件！');
                    this.$emit('submitFn', { flag: 3, submitFlag: false });
                }
            });
        }
    },
    watch: {
        caseDetailData: {
            handler(val) {
                if (val) {
                    if (!this.submitForm.leavingMarks && !this.submitForm.unifyInfo) {
                        this.submitForm.leavingMarks = val.leavingMarks || '';
                        this.submitForm.unifyInfo = val.unifyInfo || '';
                    }
                }
            },
            immediate: true
        }
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/caseCard.css';
.caseInfo {
    .title_row {
        .btn_groups {
            text-align: right;
        }
    }
    .card_content {
        padding-bottom: 20px;
        .situation {
            display: flex;
            .submit_form {
                display: flex;
                align-items: center;
                margin-top: -10px;
                .el-form-item {
                    margin-bottom: 0px;
                    position: relative;
                    top: 1px;
                    .styleInput {
                        /deep/ .el-input__inner {
                            height: 25px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}
</style>