<template>
    <div class="wrap">
        <div class="page_title"><b>案件详情</b></div>
        <div class="page_content clearfix">
            <div :class="{ cards_content: 1, cards_left: 1, fl: 1, 'cards_left-width': 1 }">
                <div class="btn_groups" v-if="btnMenuData">
                    <el-button type="primary" size="mini" v-if="btnMenuData[500]" @click="approvalFlag = true">审 核</el-button>
                    <el-button type="primary" size="mini" v-if="btnMenuData[400]" @click="submitFlag = true">提 交</el-button>
                </div>
                <!-- 诉讼案件详情 -->
                <case-info :caseDetailData="caseDetailData"></case-info>
                <!-- 处理情况 -->
                <case-situation ref="caseSituationRef" :caseDetailData="caseDetailData" :btnMenuData="btnMenuData" @submitFn="submitFn"></case-situation>
                <!-- 案件材料 -->
                <case-materia :caseDetailData="caseDetailData" :btnMenuData="btnMenuData" @getCaseDetail="getCaseDetail"></case-materia>
            </div>
            <!-- 案件日志 -->
            <div :class="{ cards_right: 1, fr: 1, 'cards_right-width': 1 }">
                <case-log ref="caseLogRef" :caseDetailData="caseDetailData" @hiddenCaseLog="hiddenCaseLog"></case-log>
            </div>
        </div>
        <!-- 审核弹窗 -->
        <el-dialog class="process_popup" title="审核" :visible.sync="approvalFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="30%">
            <div class="dialog_content">
                <el-form :model="approvalForm" :rules="approvalRules" ref="approvalRef" label-width="120px">
                    <el-form-item label="审核结果：" prop="agree">
                        <el-radio v-model="approvalForm.agree" :label="true">通过</el-radio>
                        <el-radio v-model="approvalForm.agree" :label="false">拒绝</el-radio>
                    </el-form-item>
                    <el-form-item label="审核意见：" prop="remark" v-if="!approvalForm.agree">
                        <el-input v-model="approvalForm.remark" type="textarea" :rows="4" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="approvalFn(true)">提 交</el-button>
                <el-button @click="approvalFn(false)">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 提交弹窗 -->
        <el-dialog class="process_popup" title="提交" :visible.sync="submitFlag" center :modal-append-to-body="false" :close-on-click-modal="false" width="30%">
            <div class="dialog_content">提交后该案件将不可操作 ！确认“提交”案件 ？</div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitFn({ flag: 1 })">确 认</el-button>
                <el-button @click="submitFn({ flag: 0 })">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import CaseInfo from '@/components/complaint/caseInfo.vue';
import CaseSituation from '@/components/complaint/caseSituation.vue';
import CaseMateria from '@/components/complaint/caseMateria.vue';
import CaseLog from '@/components/complaint/caseLog.vue';
export default {
    components: {
        CaseInfo,
        CaseSituation,
        CaseMateria,
        CaseLog
    },
    data() {
        return {
            approvalFlag: false,
            submitFlag: false,
            navForm: {
                caseNo: '',
                value1: [],
                value2: [],
                value3: '',
                value4: '',
                value5: '',
                page: 1,
                size: 10,
                total: 0
            },
            btnMenuData: null,
            agreeOptions: [
                {
                    value: true,
                    label: '同意'
                },
                {
                    value: false,
                    label: '不同意'
                }
            ],
            caseDetailData: null,
            approvalForm: {
                agree: null,
                remark: ''
            },
            approvalRules: {
                agree: [{ required: true, message: '请选择', trigger: 'blur' }],
                remark: [{ required: true, message: '请填写', trigger: 'blur' }]
            },
            complaintId: '',
            submitForm: {
                unifyInfo: '',
                leavingMarks: ''
            }
        };
    },
    mounted() {
        this.handleUrlQueryData();
    },
    methods: {
        hiddenCaseLog() {},
        handleUrlQueryData(waitFlag) {
            if (this.$route.query) {
                // 案件详情
                if (this.$route.query.complaintId && !waitFlag) {
                    this.complaintId = this.$route.query.complaintId;
                    this.getCaseDetail(true);
                }

                // 打开复核弹窗
                // if (this.$route.query.examine && this.caseDetailData && !this.caseDetailData.approveMenu && this.btnMenuData && this.btnMenuData[500] && waitFlag) {
                //     this.approvalFlag = true;
                // }
            }
        },
        updatePageInfo() {
            this.getCaseDetail();
            this.caseDetailBtnTree();
        },
        getCaseDetail(btnMenuFlag) {
            this.caseDetailData = null;
            this.instance
                .post('/complaintInfo/detail', {
                    complaintId: this.complaintId
                })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.caseDetailData = data.data;
                        if (btnMenuFlag) {
                            this.caseDetailBtnTree();
                        }
                    } else {
                        this.$message.error(data.message);
                    }
                });
        },
        caseDetailBtnTree() {
            this.instance
                .post('/complaintInfo/getMenu', {
                    complaintId: this.complaintId
                })
                .then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.btnMenuData = {};
                        data.data.forEach((item) => {
                            this.btnMenuData[item.code] = item;
                        });
                        setTimeout(() => {
                            this.handleUrlQueryData(true);
                        }, 100);
                    }
                });
        },
        closeCaseFn(confirm) {
            if (confirm) {
                this.$refs.closeCaseRef.validate((valid) => {
                    if (valid) {
                        this.closeCaseInterface();
                    }
                });
            } else {
                this.closeCaseFlag = false;
                // this.$refs.closeCaseRef.resetFields();
            }
        },
        selectChange() {
            this.$forceUpdate();
        },
        approvalFn(confirm) {
            if (confirm) {
                this.$refs.approvalRef.validate((valid) => {
                    if (valid) {
                        this.approvalInterface();
                    }
                });
            } else {
                this.approvalFlag = false;
                this.$refs.approvalRef.resetFields();
            }
        },
        approvalInterface() {
            this.approvalForm.complaintId = this.caseDetailData.id;
            this.instance.post('/complaintInfo/auditComplaint', this.approvalForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.$message.success('操作成功！');
                    this.approvalFlag = false;
                } else {
                    this.$message.error(data.message);
                }
                this.updatePageInfo();
            });
        },
        submitFn(confirmData) {
            switch (confirmData.flag) {
                case 1:
                    this.$refs.caseSituationRef.validateForm();

                    break;
                case 0:
                    this.submitFlag = false;

                    break;
                case 2:
                    confirmData.complaintId = this.caseDetailData.id;
                    this.instance.post('/complaintInfo/commitComplaint', confirmData).then((res) => {
                        let data = res.data;
                        if (data.code == 1) {
                            this.$message.success('提交成功！');
                            this.submitFlag = false;
                            // window.open('', '_self').close();
                        } else {
                            this.$message.error(data.message);
                        }
                        this.updatePageInfo();
                    });

                    break;
                case 3:
                    this.submitFlag = confirmData.submitFlag;

                    break;

                default:
                    break;
            }
        },
        editInfo(data) {
            this.litigationInfoShow = data;
        }
    }
};
</script>
<style>
@import '../../assets/css/table_public.css';
@import '../../assets/css/caseCard.css';
</style>
<style lang="less" scoped>
.page_title {
    margin-bottom: 10px;
}
.page_content {
    ::-webkit-scrollbar {
        width: 0px;
        height: 10px;
        // background-color: red;
    }
    .cards_left {
        position: relative;
        .btn_groups {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 5;
        }
    }
}
// 弹窗
.dialog_content {
    .el-row {
        margin-bottom: 30px;
        .title_col {
            text-align: right;
        }
    }
    .el-row:last-child {
        margin-bottom: 0;
    }
}
.process_popup {
    .el-form-item__content {
        .el-select {
            width: 100%;
        }
    }
}

.textareaNumPosition {
    /deep/ .el-input__count,
    .el-input__count,
    /deep/ span {
        background: none !important;
        bottom: -25px !important;
        right: 3px !important;
    }
}
</style>