<template>
    <div class="wrap">
        <div class="page_title"><b>案件列表</b></div>
        <el-tabs class="register_tabs" v-model="editableTabsValue" type="card" @tab-click="clickTab" v-if="0">
            <template v-for="(item, index) in editableTabs">
                <el-tab-pane :key="index" :label="item.title" :name="item.menuTypeNum"></el-tab-pane>
            </template>
        </el-tabs>
        <!--检索部分  -->
        <div class="search_box public_box">
            <el-form :inline="true" :model="navForm">
                <el-form-item label="关键字：">
                    <el-input class="width_250" v-model="navForm.keyword" type="text" placeholder="请输入保单号/归档编号/原告/骑手" clearable></el-input>
                </el-form-item>
                <el-form-item label="是否挂起：">
                    <el-select class="width_110" v-model="navForm.suspendedFlag" clearable placeholder="请选择">
                        <el-option v-for="item in suspended" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="录入日期：">
                    <el-row style="width: 350px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startAddDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endAddDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="结案日期：">
                    <el-row style="width: 350px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startCloseDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endCloseDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="履行日期：">
                    <el-row style="width: 350px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startPayDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endPayDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="开庭时间：">
                    <el-row style="width: 350px">
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.startCourtDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                        <el-col class="line" style="text-align: center" :span="2">至</el-col>
                        <el-col :span="11">
                            <el-date-picker placeholder="请选择日期" clearable v-model="navForm.endCourtDate" type="date" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="是否委托律所：">
                    <el-select class="width_110" v-model="navForm.entrustFlag" clearable placeholder="请选择">
                        <el-option v-for="item in entrustOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="案件状态：">
                    <el-select class="width_190" v-model="navForm.checkStatus" multiple clearable placeholder="请选择">
                        <el-option v-for="item in checkStatusOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="流程状态：">
                    <el-select class="width_210" v-model="navForm.caseStep" multiple clearable placeholder="请选择">
                        <el-option v-for="item in caseStepOptions" :key="item.code" :label="item.desc" :value="item.code"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="当前处理人：">
                    <el-select class="width_110" v-model="navForm.currentHandlerUser" filterable clearable placeholder="请选择">
                        <el-option v-for="item in caseUserOptions" :key="item.username" :label="item.name" :value="item.username"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="margin-left: 10px" @click="searchCasePage(true)">查询</el-button>
                    <el-button type="primary" style="margin-left: 20px" v-if="btnMenuData[100]" @click="switchDialog(true)">录入案件</el-button>
                    <el-button type="primary" style="margin-left: 20px" v-if="btnMenuData[100]" :loading="downloadLoading" @click="downloadCase">案件导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <div class="main">
            <div class="table_box public_box">
                <el-button type="primary" :disabled="delSelectData.length < 1" @click="delCaseFlag = true" v-if="btnMenuData[100]">删 除</el-button>
                <el-table ref="multipleTable" :data="tableData" :height="tableHeight" sortable="custom" @sort-change="tableSort" @selection-change="tableSelection" v-loading="loading">
                    <el-table-column type="selection" width="45" v-if="btnMenuData[100]"> </el-table-column>
                    <el-table-column prop="filingNumber" fixed="left" label="归档编号" width="160">
                        <template slot-scope="scope">
                            <div class="tooltip_text" @click="openNewTab(scope.row.id)">
                                <overflow-txt :txt="scope.row.filingNumber"></overflow-txt>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="录入日期" width="120"></el-table-column>
                    <el-table-column prop="courtTime" label="开庭时间" width="140"></el-table-column>
                    <el-table-column prop="updateTime" label="最近更新时间" sortable width="140"></el-table-column>
                    <el-table-column prop="caseNo" label="案件号" width="200">
                        <template slot-scope="scope">
                            <div class="tooltip_text" @click="openNewTab(scope.row.id)">
                                <overflow-txt :txt="scope.row.caseNo"></overflow-txt>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="courtCaseNo" label="法院案号"></el-table-column>
                    <el-table-column prop="suspendedFlag" label="是否挂起" width="100">
                        <template slot-scope="scope">
                            <span>{{ scope.row.suspendedFlag ? '是' : '否' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="policyNo" label="保单号" width="220">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.policyNo"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="nameOfDeliveryMan" label="骑手"></el-table-column>
                    <el-table-column prop="plaintiff" label="原告"></el-table-column>
                    <el-table-column prop="productType" label="保险产品类型" width="220">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.productType"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="insureTypeName" label="险种" width="110"></el-table-column>
                    <el-table-column prop="accidentCityDistrict" label="出险省市区" width="220"></el-table-column>
                    <el-table-column prop="lawsuitAmount" label="诉讼金额" width="110"></el-table-column>
                    <el-table-column prop="checkStatusDesc" label="案件状态" width="200"></el-table-column>
                    <el-table-column prop="currentCaseStepDesc" label="流程状态" width="200"></el-table-column>
                    <el-table-column prop="currentHandler" label="当前处理人"></el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.page" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.size" :total="navForm.total"> </el-pagination>
            </div>
        </div>
        <!-- 录入案件弹窗 -->
        <enter-case v-if="dialogShow" :dialogShow="dialogShow" :btnMenuData="btnMenuData" @switchDialog="switchDialog" @openNewTab="openNewTab" @searchCasePage="searchCasePage"></enter-case>
        <!-- 删除 弹窗 -->
        <el-dialog title="删除案件" :visible.sync="delCaseFlag" :modal-append-to-body="false" width="500px" center>
            <span v-if="delSelectData.length == 1">是否确认删除 “ {{ delSelectData[0] ? delSelectData[0].filingNumber : '' }} ” 的案件</span>
            <span v-else>是否确认删除 “ {{ delSelectData[0] ? delSelectData[0].filingNumber : '' }} ”、“ {{ delSelectData[1] ? delSelectData[1].filingNumber : '' }} ” 等，共{{ delSelectData.length }}案件。</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delSelectFn(false)">取 消</el-button>
                <el-button type="primary" @click="delSelectFn(true)">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import EnterCase from '../../components/litigation/enterCase.vue';

let queryCaseRuleFlag = false;
const validatePass = (rule, value, callback) => {
    if (value.length > 0 && queryCaseRuleFlag) {
        callback(new Error('未查询到案件号，请输入正确的理赔报案号重新查询'));
    } else {
        callback();
    }
};
export default {
    components: { EnterCase },
    data() {
        return {
            delCaseFlag: false,
            delSelectData: [],
            downloadLoading: false,
            tableHeight: null,
            editableTabsValue: '0',
            editableTabs: [
                {
                    title: '活跃案件',
                    menuTypeNum: '0'
                },
                {
                    title: '挂起案件',
                    menuTypeNum: '1'
                }
            ],
            dialogShow: false,
            loading: false,
            navForm: {
                orderKey: '',
                reverseOrder: '',
                keyword: '',
                entrustFlag: '',
                caseStep: '',
                currentHandlerUser: '',
                startAddDate: '',
                endAddDate: '',
                startCourtDate: '',
                endCourtDate: '',
                startCloseDate: '',
                endCloseDate: '',
                checkStatus: '',
                suspendedFlag: null,
                page: 1,
                size: 10,
                total: 0
            },
            suspended: [
                {
                    code: true,
                    desc: '是'
                },
                {
                    code: false,
                    desc: '否'
                }
            ],
            entrustOptions: [
                {
                    value: true,
                    label: '是'
                },
                {
                    value: false,
                    label: '否'
                }
            ],
            caseStepOptions: [],
            caseUserOptions: [],
            checkStatusOptions: [],
            btnMenuData: {},
            value: '',
            tableData: [],
            addCaseForm: {
                caseNo: '',
                caseName: '',
                courtCaseNo: '',
                plaintiff: '',
                lawsuitAmount: '',
                lawsuitCause: '',
                distributeHandler: '',
                distributeHandlerUser: '',
                situation: ''
            },
            queryCaseData: {
                policyNo: null,
                productType: null,
                insureTypeName: null,
                accidentCityDistrict: null,
                nameOfDeliveryMan: null
            }
        };
    },
    mounted() {
        // 动态计算table高度
        window.onresize = () => {
            this.calcHeight();
        };
        this.calcHeight();

        this.caseCreateBtnTree();
        this.searchCasePage();
        // 案件状态
        this.getAllCaseStep();
        // 流程状态
        this.getAllCheckCaseStep();
        // 用户
        this.getCaseUser();
    },
    methods: {
        clickTab() {
            this.navForm.suspendedFlag = this.editableTabsValue != '0';
            this.searchCasePage();
        },
        calcHeight() {
            // 顶部tabs
            let registerTabsHeight = document.querySelector('.register_tabs') ? document.querySelector('.register_tabs').clientHeight : 0;
            // 搜索筛选项
            let publicBoxHeight = document.querySelector('.public_box') ? document.querySelector('.public_box').clientHeight : 0;
            // 分页
            let elPaginationHeight = document.querySelector('.el-pagination') ? document.querySelector('.el-pagination').clientHeight : 0;
            let tableHeaderHeight = 0;
            let guHeight = 175 + 25;

            this.tableHeight = window.innerHeight - registerTabsHeight - publicBoxHeight - elPaginationHeight - tableHeaderHeight - guHeight;

            this.tableHeight = this.tableHeight < 200 ? 200 : this.tableHeight;
            this.$forceUpdate();
        },
        // 按钮权限
        caseCreateBtnTree() {
            this.instance.post('/lawsuitCase/getCreateMenu', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    // INPUT_CASE(100, "录入案件"),
                    // DISTRIBUTE_CASE(200, "分配"),
                    // EDIT_CASE(300, "编辑"),
                    // EDIT_CASE_LAWYER(301, "编辑案件律师"),
                    // commission_CASE(400, "委托"),
                    // COMMIT_CASE(500, "提交"),
                    // MENTION_APPROVE_CASE(700, "提审"),
                    // APPROVE_CASE(600, "审批"),
                    // END_CASE(999, "结案");
                    this.btnMenuData = {};
                    data.data.forEach((item) => {
                        this.btnMenuData[item.code] = item;
                    });
                }
            });
        },
        getAllCaseStep() {
            this.instance.get('/lawsuitCase/getAllCaseStep', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseStepOptions = data.data;
                }
            });
        },
        getAllCheckCaseStep() {
            this.instance.get('/lawsuitCase/getAllCheckCaseStep', { params: {} }).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.checkStatusOptions = data.data;
                }
            });
        },
        getCaseUser() {
            this.instance.post('/lawsuitCase/findCaseUser', {}).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.caseUserObj = {};
                    this.caseUserOptions = data.data;
                    this.caseUserOptions.forEach((item) => {
                        this.caseUserObj[item.name] = item.username;
                    });
                }
            });
        },
        searchCasePage(queryFlag) {
            if (queryFlag) {
                this.navForm.page = 1;
            }
            this.tableData = [];
            this.instance.post('/lawsuitCase/searchCasePage', this.navForm).then((res) => {
                let data = res.data;
                if (data.code == 1) {
                    this.tableData = data.data.list;
                    this.navForm.total = data.data.total;
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        openNewTab(caseNo) {
            let a = document.createElement('a');
            a.href = `${location.origin}/litigationManage/caseDetail?caseId=${caseNo}`;
            a.target = '_blank';
            a.click();
        },
        tableSelection(selectData) {
            this.delSelectData = selectData;
        },
        delSelectFn(submitFlag) {
            if (!submitFlag) {
                this.delCaseFlag = false;
            } else {
                let delData = [];
                this.delSelectData.forEach((item) => {
                    item.caseNo && delData.push(item.id);
                });
                this.instance.post('/lawsuitCase/deleteCases', { ids: delData }).then((res) => {
                    let data = res.data;
                    if (data.code == 1) {
                        this.$message.success('删除成功！');
                    } else {
                        this.$message.error(data.message);
                    }
                    this.searchCasePage();
                });
                this.delCaseFlag = false;
            }
            this.delSelectData = [];
            this.$refs.multipleTable.clearSelection();
        },
        handleCurrentChange(val) {
            this.navForm.page = val;
            this.searchCasePage();
        },
        handleSizeChange(val) {
            this.navForm.size = val;
            this.searchCasePage();
        },
        switchDialog(switchFlag) {
            this.dialogShow = switchFlag;
        },
        downloadCase() {
            this.downloadLoading = true;
            this.instance({
                method: 'post',
                url: '/lawsuitCase/downloadCasePage',
                data: this.navForm,
                responseType: 'blob'
            }).then((res) => {
                this.downloadLoading = false;
                let url = window.URL.createObjectURL(new Blob([res.data]));
                let link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', this.editableTabs[this.editableTabsValue].title + ' - 案件列表.xls'); //指定下载后的文件名，防跳转
                document.body.appendChild(link);
                link.click();
            });
        },
        tableSort(sortMap) {
            if (sortMap.order) {
                this.navForm.reverseOrder = sortMap.order === 'descending';
                this.navForm.orderKey = sortMap.prop == 'updateTime' ? 'update_time' : '';
            } else {
                this.navForm.reverseOrder = '';
                this.navForm.orderKey = '';
            }
            this.searchCasePage(true);
        }
    }
};
</script>
<style>
@import '../../assets/css/table_public.css';
</style>
<style lang="less" scoped>
.search_box {
    .el-form {
        .datePickerWidth {
            width: 250px;
        }
    }
}
.table_box {
    .tooltip_text {
        cursor: pointer;
        color: #409eff;
    }
    .winRate {
        display: inline-block;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        margin-right: 5px;
    }
    .greenColor {
        background: #67c23a;
    }
    .redColor {
        background: red;
    }
}
</style>